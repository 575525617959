<template>
  <div class="container detail" v-loading="loading">
    <div>
      <div class="top">
        <div class="img">
          <img
            :src="renderImage()"
            :class="!isImage() && 'basic-profile'"
            alt="profile"
          />
        </div>
        <div class="info">
          <h3>
            {{ name }}
            <em
              ><span>{{ title !== "NaN" ? title : "" }}</span></em
            >
          </h3>
          <ul>
            <li v-for="(data, i) in specialty" :key="i">{{ data }}</li>
          </ul>
          <!-- <p v-for="(data, i) in specialty" :key="i" >{{data}}</p> -->
          <p>{{ address }}</p>
        </div>
      </div>
      <div class="bottom flexB">
        <div class="left">
          <div>
            <h4>Specialty</h4>
            <ul>
              <li v-for="(data, i) in specialty" :key="i">{{ data }}</li>
            </ul>
            <!-- <p v-for="(data, i) in specialty" :key="i" >{{data}}</p> -->
          </div>
          <div>
            <h4>Gender</h4>
            <p>{{ gender }}</p>
          </div>
          <div>
            <h4>Address</h4>
            <p>{{ address }}</p>
          </div>
          <div>
            <h4>Hospital</h4>
            <p v-for="(data, i) in hospitals" :key="i">{{ data }}</p>
          </div>
          <div>
            <h4>Contact</h4>
            <p>{{ contact }}</p>
          </div>
          <div>
            <h4>Overview</h4>
            <pre>{{ overview }}</pre>
          </div>
          <div>
            <h4>Description</h4>
            <pre>{{ description }}</pre>
          </div>
        </div>
        <div class="right">
          <div>
            <h4>Location</h4>
            <div class="map">
              <div id="map"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script
  async
  defer
  src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBHz9WFkmS_J-IDeGpOfMy9OQTGzbs8I94&language=en"
></script>
<script>
// import { mapState } from "vuex";
// import moment from "moment";
// import { format } from "@/mixins/format";
import { fetchMedicalDetail } from "@/api/medical";
export default {
  // mixins: [format],
  components: {},
  data() {
    return {
      // moment: moment,
      // loading: false,
      // startYmd: "",
      // postList: [],
      // images: [],
      // topList: [],
      // total: 0,
      // currentPage: 1,
      map: null,
      geocoder: null,
      mapCenter: { lat: 35.105696, lng: 129.042857 },
      // bands: {
      //   lat: 35.106187,
      //   lng: 129.042943,
      // },
      // bands2: {
      //   lat: 35.105556,
      //   lng: 129.04393,
      // },
      //labels: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      // labelIndex: 0,
      address: "",
      contact: "",
      description: "",
      gender: "",
      hospitals: [],
      img_url: "",
      name: "",
      overview: "",
      specialty: [],
      title: "",
      loading: false,
    };
  },
  created() {
    if (!this.$route.params.medicalId) {
      this.$router.push("/");
    } else {
      this.getMedicalDetail();
    }
  },
  // computed: {
  //   ...mapState(["userId"]),
  // },
  mounted() {
    // this.loading = true;
    this.initMap();
    // this.setMarker(this.mapCenter, "ce");
    // this.setMarker(this.bands, "1");
    // this.setMarker(this.bands2, "2");
  },
  methods: {
    isImage() {
      return !!this.img_url;
    },
    renderImage() {
      return this.img_url !== ""
        ? this.img_url
        : this.gender === "Male"
        ? require(`@/assets/images/male_avatar.png`)
        : require(`@/assets/images/female_avatar.png`);
    },
    getMedicalDetail() {
      this.loading = true;
      fetchMedicalDetail(this.$route.params.medicalId).then((res) => {
        if (res.data.status == 200) {
          //console.log(res.data.data);
          this.address = res.data.data.address;
          this.contact = res.data.data.contact;
          this.description = res.data.data.description;
          this.gender = res.data.data.gender;
          this.hospitals = res.data.data.hospitals;
          this.img_url = res.data.data.img_url;
          this.name = res.data.data.name;
          this.overview = res.data.data.overview;
          this.specialty = res.data.data.specialty;
          this.title = res.data.data.title;
          //console.log(this.hospitals);
          //console.log(this.specialty);
          if (this.address != "" || this.address != undefined) {
            this.codeAddress(this.geocoder, this.map);
          }
        } else {
          return alert(res.data.message);
        }
        this.loading = false;
      });
    },
    initMap() {
      //getElementById로 map id 속성의 요소를 가져옴
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: this.mapCenter, //center로 할 위도, 경도를 지정
        zoom: 17, //zoom size를 지정
        maxZoom: 20,
        minZoom: 3,
        streetViewControl: true,
        gestureHandling: "cooperative",
        mapTypeControl: true,
        zoomControl: true,
        scaleControl: true,
        rotateControl: true,
        fullscreenControl: true,
        mapTypeControl: true,
      });
      this.geocoder = new google.maps.Geocoder();
      //this.codeAddress(this.geocoder, map);
    },
    //지도에 마커를 찍는 함수.
    // setMarker(Points, Label) {
    //   const markers = new google.maps.Marker({
    //     position: Points,
    //     map: this.map,
    //     label: {
    //       text: Label,
    //       color: "#FFF",
    //     },
    //   });
    // },
    codeAddress(geocoder, map) {
      geocoder.geocode({ address: this.address }, function (results, status) {
        if (status === "OK") {
          map.setCenter(results[0].geometry.location);
          var marker = new google.maps.Marker({
            map: map,
            // label: this.labels[this.labelIndex++ % this.labels.length],
            // label: {
            //   text: ce,
            //   color: "#FFF",
            // },
            position: results[0].geometry.location,
          });
        } else {
          /*220801 - remove map temporarily*/
          // alert(
          //   "Geocode was not successful for the following reason: " + status
          // );
          console.error(
            "Geocode was not successful for the following reason: ",
            status
          );
        }
      });
    },
  },
};
</script>
